@font-face {
  font-family: "Prompt-Regular";
  src: url("../font/Prompt-Regular.ttf");
}

@font-face {
  font-family: "Prompt-Light";
  src: url("../font/Prompt-Light.ttf");
}

@font-face {
  font-family: "Prompt-Bold";
  src: url("../font/Prompt-Bold.ttf");
}

.regular-th {
  font-family: "Prompt-Regular", "Prompt-Regular";
}

.light-th {
  font-family: "Prompt-Light", "Prompt-Light";
}

.bold-th {
  font-family: "Prompt-Bold", "Prompt-Bold";
}

/*product card in product page*/
.card:hover {
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em,
    rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
  transform: translateY(-3px) scale(1);
}

.button-like-a {
  background: none!important;
  border: none;
  cursor: pointer;
  outline-color: transparent;
  outline: none;
}

.button-like-a:hover{
  background: none!important;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  outline-color: transparent;
  outline: none;
}








